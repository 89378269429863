<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bienvenido
          </b-card-title>
          <b-card-text class="mb-2">
            Inicia sesión para continuar
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Correo electrónico"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="jorge@ejemplo.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="secondary"
                block
                :disabled="validatingData"
                @click="validationForm"
              >
                <b-spinner
                  v-if="validatingData"
                  class="mr-50"
                  small
                />
                <span class="align-middle">Iniciar sesión</span>
              </b-button>
              <!-- /submit button -->
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle,
  BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import store from '@/store/index'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    BButton,
    BSpinner,
    BCardText,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // password: 'Hola2021*',
      // userEmail: 'admin@gmail.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      locale: 'es',

      validatingData: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.validatingData = true

          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const { data } = response.data

              useJwt.setToken(data.Token)
              useJwt.setRefreshToken(data.Token)

              const permissions = data.Level ? this.removePermissions(data.permissions) : data.permissions
              data.permissions = permissions

              localStorage.setItem('userData', JSON.stringify(data))


              this.$ability.update(permissions)

              this.$router.replace(getHomeRouteForLoggedInUser(data.Role))
                .then(() => {
                  this.showToast(`Bienvenido ${data.Name || data.LastName}`, `Has ingresado correctamente como ${data.Role}.`, 'success')
                })
            })
            .catch(error => {
              this.showToast('Error de validación', 'El correo electrónico y/o contraseña es incorrecto.', 'danger')
              this.$refs.loginValidation.setErrors(error.response.data.error)
            })
            .finally(() => {
              this.validatingData = false
            })
        }
      })
    },
    removePermissions(permissions) {
      const permissionToRemove = {
        "action": "read",
        "subject": "Commissions"
      }

      const index = permissions.findIndex(permission => JSON.stringify(permission) === JSON.stringify(permissionToRemove));

      if (index !== -1) {
        permissions.splice(index, 1);
      }

      return permissions;
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
